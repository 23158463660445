import React from "react"
import { useTranslation } from "react-i18next"
import "./pricing.css"
import "../FAQ/faq.css"



const Pricing = () => {
  const [t, i18n] = useTranslation()


  return (
    <div className="tadafuq__FAQ  section__padding">
      <table>
        <thead> 
          <tr  className={i18n.language === "en" ? 'align-left' : 'align-right' }>
            <th style={{width: "33%"}}>{t("pricing.features")}</th>
            <th style={{width: "33%"}}>{t("pricing.big_banner")}</th>
            <th style={{'padding-left': "7%"}}>{t("pricing.showcase")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="feature">{t("pricing.size")}</td>
            <td>728x90 PIXEL</td>
            <td style={{'padding-left': "7%"}}>250x300 PIXEL</td>
          </tr>
          <tr>
            <td className="feature">{t("pricing.appearances")}</td>
            <td>100,000</td>
            <td style={{'padding-left': "7%"}}>100,000</td>
          </tr>
          <tr>
            <td className="feature">{t("pricing.pricing")} (SR)</td>
            <td>2250 SR</td>
            <td style={{'padding-left': "7%"}}>2250 SR</td>
          </tr>
          <tr>
            <td className="feature">{t("pricing.pricing")} (USD)</td>
            <td>600 USD</td>
            <td style={{'padding-left': "7%"}}>600 USD</td>
          </tr>
          <tr>
            <td className="feature">{t("pricing.ad_website")}</td>
            <td>{t("pricing.all_platforms")}</td>
            <td style={{'padding-left': "7%"}}>{t("pricing.all_platforms")}</td>
          </tr>
        </tbody>
      </table>
      <div className="section__padding terms" style={{ margin: "auto", width: "73vw"}}>
        <h4>{t("pricing.terms.terms_and_notes")}</h4>
        <p>
          - {t("pricing.terms.vat")}
        </p>
        <p>
          - {t("pricing.terms.prices")}
        </p>
        <p>
          - {t("pricing.terms.shortes_period")}
        </p>
        <p>
          - {t("pricing.terms.size")}
        </p>
        <p>
          - {t("pricing.terms.legal")}
        </p>
        <p>
          - {t("pricing.terms.design")}
        </p>
        <p>
          - {t("pricing.terms.management")}
        </p>
        <p>
          - {t("pricing.terms.format")}
        </p>
        
      </div>
    </div>
  )
}

export default Pricing