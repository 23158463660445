import React, { useEffect } from "react"
import "./App.css"
import { Route, Routes } from "react-router-dom"
import Home from "./screens/home/Home"
import { FaqPage2, FaqPage, Login, Signup, ContactUs, Pricing } from "./screens"
import { useTranslation } from "react-i18next"

const App = () => {
  const [t, i18n] = useTranslation()

  useEffect(() => {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "ar");
    }
    const storedLanguage = localStorage.getItem("language");
    i18n.changeLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    document.body.dir = (i18n.language === "ar" && "rtl") || "ltr";
    document.title = t("app_title");
    localStorage.setItem("language", i18n.language);
  }, [i18n.language]);

  return (
    <div className="App">
      <div>
        <Routes>
          {" "}
          <Route path="/" element={<Home />} />
          <Route path="/FAQPublisher" element={<FaqPage2 />} />
          <Route path="/FQAdvertiser" element={<FaqPage />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Pricing" element={<Pricing />} />
        </Routes>
      </div>
    </div>
  )
}

export default App
