import React, { useState } from "react"
import "./navbar.css"
import { RiCloseLine, RiMenuFill } from "react-icons/ri"
import logo from "../../assets/images/logoh.svg"
import elogo from "../../assets/images/elogo.png"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Menu } from "./menu"

const Navbar = () => {
  const [t, i18n] = useTranslation()
  const [toggleMenu, setToggleMenu] = useState(false)
  return (
    <nav className="tadafuq__navbar ">
      <div className="tadafuq__navbar-links">
        <div className="tadafuq__navbar-links_logo">
          <Link to="/"> {i18n.language === "ar" && <img src={logo} alt="logo" />}</Link>
          <Link to="/"> {i18n.language === "en" && <img src={elogo} alt="logo" />}</Link>
        </div>
      </div>
      <div className="tadafuq__navbar-links_containor ">
        <Menu />
      </div>
      <div className="tadafuq__navbar-sign  ">
        <Link to="/Signup">
          <button type="button">{t("SignUp")}</button>
        </Link>
      </div>
      <div className="tadafuq__navbar-menu">
        <RiMenuFill color="#fff" size={27} onClick={() => setToggleMenu(true)} />

        {toggleMenu && (
          <div className="tadafuq__navbar-menu_containor flex__center slide-bottom">
            <RiCloseLine color="#fff" size={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
            <div className="tadafuq__navbar-menu_containor-links">
              <ul>
                <li onClick={() => setToggleMenu(false)}>
                  <Link to="/">{t("navmain")}</Link>
                </li>
                <li onClick={() => setToggleMenu(false)}>
                  <Link to="/FQAdvertiser">{t("navAdvertiser")}</Link>
                </li>
                <li>
                  <Link to="/FAQPublisher">{t("navPublisher")}</Link>
                </li>
                <li>
                  <Link to="/Pricing">{t("pricing.pricing")}</Link>
                </li>
                <li onClick={() => setToggleMenu(false)}>
                  <Link to="/Login">{t("navLogin")}</Link>
                </li>
                <li onClick={() => setToggleMenu(false)}>
                  {i18n.language == "ar" && (
                    <li
                      className="langButton"
                      onClick={() => {
                        i18n.changeLanguage("en")
                      }}
                    >
                      English
                    </li>
                  )}

                  {i18n.language == "en" && (
                    <li
                      className="langButton"
                      onClick={() => {
                        i18n.changeLanguage("ar")
                      }}
                    >
                      العربية
                    </li>
                  )}
                </li>
              </ul>

              <div className="tadafuq__navbar-menu_containor-links-sign">
                <Link to="/Signup">
                  <li onClick={() => setToggleMenu(false)}>{t("SignUp")}</li>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Navbar
