import bug from "../assets/images/bug.svg"
import handshake from "../assets/images/handshake.svg"
import chart from "../assets/images/chart.svg"
import picon from "../assets/images/picon.svg"
import pvideo from "../assets/images/video.svg"
import map from "../assets/images/map.svg"
import setting from "../assets/images/settings.svg"
import flogo from "../assets/images/flogo.svg"
import mail from "../assets/images/mail.svg"
import phone from "../assets/images/phone.svg"
import whatsup from "../assets/images/whatsup.svg"
import cardimg1 from "../assets/images/cardimg1.png"
import cardimg2 from "../assets/images/cardimg2.png"
import rating from "../assets/images/rating.png"
import eflogo from "../assets/images/efooterlogo.png"
export default {
  bug,
  handshake,
  chart,
  picon,
  pvideo,
  map,
  setting,
  flogo,
  mail,
  phone,
  whatsup,
  cardimg1,
  cardimg2,
  rating,
  eflogo
}
