import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "./assets/locales/en/translation.json"
import translationAr from "./assets/locales/ar/translation.json"
import LanguageDetector from "i18next-browser-languagedetector"
const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAr,
    
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "ar",

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
