import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import "./assets/fonts/Swissra/Swissra-Bold.otf"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import App from "./App"
import ScrollToTop from "./components/ScrollToTop"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
)
