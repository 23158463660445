import React from "react"
import Forminput from "../../components/form/Forminput"
import { useState } from "react"
import "./login.css"
import loginlogo from "../../assets/images/loginlogo.png"
import eloginlogo from "../../assets/images/eloginlogo.png"
import { Link } from "react-router-dom"
import { Navbar } from "../../components"
import { useTranslation } from "react-i18next"

const Login = () => {
  const [t, i18n] = useTranslation()

  const tadafuq_login_bg = i18n.language == "en" ? "tadafuq__login-en" : "tadafuq__login-ar"

  const placeholder = {
    email: t("placeholder_e"),
    password: t("password"),
  }
  const errorMessage = {
    email: t("errorMessage_email"),
    password: t("errorMessage_password"),
  }
  const [values, setValues] = useState({
    email: "",
    password: "",
  })
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: placeholder.email,
      errorMessage: errorMessage.email,
      label: placeholder.email + ":",
      required: true,
    },

    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: placeholder.password,
      errorMessage: errorMessage.password,
      label: placeholder.password + " : ",
      required: true,
    },
  ]
  const handelSubmit = e => {
    e.preventDefault()
  }

  const onChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <div className={tadafuq_login_bg}>
      <div className="tadafuq__login-links ">
        <ul>
          <li>
            <Link to="/">{t("navmain")}</Link>
          </li>
          <li>
            <Link to="/FQAdvertiser">{t("navAdvertiser")}</Link>
          </li>
          <li>
            <Link to="/FAQPublisher">{t("navPublisher")}</Link>
          </li>
          <li>
            <Link to="/Login">{t("navLogin")}</Link>
          </li>
          <li>
            <Link to="/Signup">{t("SignUp")}</Link>
          </li>
        </ul>
      </div>
      <div className="l-nav">
        {" "}
        <Navbar />
      </div>
      <div className="tadafuq__login_form-box flex__center ">
        <div className="tadafuq__login-form">
          <div className="tadafuq__login-logo flex__center  ">
            <Link to="/"></Link>
            <Link to="/"> {i18n.language == "ar" && <img src={loginlogo} alt="logo-tadafuq" />}</Link>
            <Link to="/">
              {" "}
              {i18n.language == "en" && <img src={eloginlogo} alt="logo-tadafuq" className="elogo" />}
            </Link>
          </div>
          <div className="tadafuq__login-content">
            <form onSubmit={handelSubmit}>
              <h1>{t("navLogin")}</h1>
              {inputs.map(input => (
                <Forminput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
              ))}{" "}
              <div className="forgotpassowrd">
                <div className="labels">
                <div className="checkbox-form">
                  <input type="checkbox" />
                  <label htmlFor="#">{t("remberme")} </label>
                </div>
                </div>
                <div className="f-password">
                  <p>
                    {" "}
                    {t("forgotpassword")} <Link to="/Signup">{t("newaccount")}</Link>{" "}
                  </p>
                </div>
              </div>
              <button> {t("navLogin")}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
