import React from "react"
import "./questions.css"
import Cards from "../../components/cards/Cards"
import { useTranslation } from "react-i18next"
const Questions = () => {
  const [t, i18n] = useTranslation()
  return (
    <div className="tadafuq__question section__padding  flex__center">
      <div className="tadafuq__question-title ">
        <h3>{t("QuestionTitle")}</h3>
      </div>
      <div className="tadafuq__question-subtitle ">
        <p> {t("Question_sub_title")}</p>
      </div>

      <div className="tadafuq__question-cards">
        <Cards />
      </div>
    </div>
  )
}

export default Questions
