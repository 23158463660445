import React from "react"
import "./faqpage.css"
import { useTranslation } from "react-i18next"
import FAQ from "../../components/FAQ/FAQ"
import { Navbar } from "../../components"
import { Footer } from "../../containers"

const FAQpage = () => {
  const [t] = useTranslation()
  return (
    <div className="tadafuq__faqPage  ">
      <Navbar />
      <div className="tadafuq__faqPage-banner section__padding flex__center">
        <div className="tadafuq__faqpage-banner_content">
          <h2>{t("QuestionTitle")}</h2>
          <h4>{t("QAsubtitle_A")}</h4>
          <p>{t("QA_subtitle")}</p>
        </div>
      </div>
      <FAQ />

      <Footer />
    </div>
  )
}

export default FAQpage
