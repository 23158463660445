import { images } from "../constants"

const advantages1 = [
  {
    imgUrl: images.chart,
    title: "تحقيق أعلى عوائد استثمارية للإعلانات الرقمية .",
    enTitle: "Achieving the highest returns on investment for digital ads.",
  },
  {
    imgUrl: images.picon,

    title: "الشريحة المستهدفة من الجمهور بدقة عالية .",
    enTitle: "Reach Relevant Audiences",
  },
  {
    imgUrl: images.handshake,

    title: "مناسبة للعمل مع مختلف أحجام قطاع الأعمال .",
    enTitle: "Works For Businesses Of All Sizes",
  },
  {
    imgUrl: images.setting,

    title: "طريقة تثبيت وإعدادات سهلة وسريعة .",
    enTitle: "Simple, Fast Setup",
  },

  {
    imgUrl: images.bug,

    title: "تقنيات متطورة لإزالة والتخلص من طرق التحايل .",
    enTitle: "Fraud Detection Technology",
  },
  {
    imgUrl: images.pvideo,

    title: "متوافقة مع معايير وقوالب مشغلات فيديو الإعلانات الرقمية IAB VAST 4.2",
    enTitle: "IAB VAST 4.2",
  },
  {
    imgUrl: images.map,

    title: "تقنية متطورة للاستهداف الجغرافي المتقدم .",
    enTitle: "Micro Geo Targeting",
  },
  {
    imgUrl: images.rating,

    title: "النشر في مجموعة محددة من نخبة المواقع الالكترونية ذات المحتوى المميز",
    enTitle: "Publishing to a specific group of sites with featured content",
  },
]

const services = [
  {
    title:
      "ضمان المعلن ظهور اعلاناته فقط في نخبة المواقع السعودية وليس في اي مواقع أخرى خارج المملكة أو مواقع احتيالية غير حقيقية . ",

    enTitle:
      "The advertiser guarantees that his ads appear only on Saudi sites and not on any other sites outside the Kingdom or on unreal fraudulent sites.",
  },
  {
    title: "ان يضاعف المعلن عائد الاستثمار على الإعلان للوصول للفئة المستهدفة بدون أي هدر أو مواقع مزيفة.",
    enTitle:
      "That the advertiser doubles the return on investment in advertising to reach the target group without any waste or fake sites.",
  },
  {
    title: "استهداف الجمهور في مدينة أو مدن معينة أو نطاق جغرافي محدد.",
    enTitle: "We are targeting an audience in a specific city or cities or specific geographic area.",
  },
  {
    title: " بث جميع أنواع الإعلانات الرقمية سواء بنرات او فيديو.",
    enTitle: "Broadcasting all types of digital ads, whether banners or video.",
  },
  {
    title: "الحصول على تقارير وإحصائيات واضحة ودقيقة عن أداء الحملة الاعلانية.",
    enTitle: " Get clear and accurate reports and statistics on the performance of the advertising campaign.",
  },
  {
    title: "وصول الإعلانات إلى المواقع الموثوقة للناشرين من خلال الشبكة.",
    enTitle: "Advertisements reach the trusted sites of publishers through the network.",
  },
]
const questions = [
  {
    imgUrl: images.cardimg1,
    category: "خاص للمعلنين",
    question: " ماهي شبكة تدفق للاعلانات الرقمية ؟",
    answer:
      "تدفق هي شبكة إعلانات رقمية تركز بالدرجة الأولى على منطقة الشرق الأوسط وشمال إفريقيا بقدرات فنية عالمية. مما يعني أنه بإمكان المعلن أن يضع بانرات الإعلانات الرقمية إضافة إلى إعلانات الفيديو مع الخبراء في شبكة إعلانات رقمية احترافية.......",
    QALink: "/FQAdvertiser",
    category_EN: "Advertiser",
    question_EN: "WHAT IS TADAFUQ?",
    answer_EN:
      "Tadafuq is an Ad Network that focuses on the MENA region with international capabilities. This means that you can post your banners and video ads with the experts in the region to display on relevant publisher websites.",
  },
  {
    imgUrl: images.cardimg2,
    category: "خاص للناشرين",
    question: "كيف يعمل برنامج شبكة تدفق للإعلانات الرقمية بالنسبة للناشر ؟",
    answer:
      "تدفق هي شبكة إعلانات رقمية تستهدف الإعلانات المناسبة للمحتوى، بحيث يسمح برنامج الناشر لأصحاب المواقع الإلكترونية،..",
    QALink: "/FAQPublisher",
    category_EN: "Publisher",
    question_EN: "How does the Publisher Program at Tadafuq work?",
    answer_EN:
      "Tadafuq is a digital advertising network that contextually targets ads. The Publisher Program allows website owners...",
  },
]

const QA = [
  {
    Q: "ماهي “شبكة تدفق” ؟",
    A: "تدفق هي شبكة إعلانات رقمية تركز بالدرجة الأولى على منطقة الشرق الأوسط وشمال إفريقيا بقدرات فنية عالمية. مما يعني أنه بإمكان المعلن أن يضع بانرات الإعلانات الرقمية إضافة إلى إعلانات الفيديو مع الخبراء في شبكة إعلانات رقمية احترافية تستطيع بث وعرض تلك الإعلانات في مواقع الناشرين ذات العلاقة",
    Q_EN: "What is Tadafuq?",
    A_EN: "Tadafuq is an Ad Network that focuses on the MENA region with international capabilities. This means that you can post your banners and video ads with the experts in the region to display on relevant publisher websites. ",
  },
  {
    Q: "هل بالإمكان بث الإعلانات النصية والبانرات باستخدام شبكة تدفق؟",
    A: "نعم بالإمكان عمل ذلك،إضافة إلى أنه بالإمكان بث إعلانات الفيديو عبر شبكة تدفق للإعلانات الرقمية والتي لايمكن عرضها إلا في عدد محدود من شبكات الإعلانات الرقمية، وشبكة تدفق هي إحدى تلك الشبكات القادرة على بث جميع أنواع الإعلانات الرقمية بما في ذلك إعلانات الفيديو. ولقد أظهرت أبحاث الإعلانات الرقمية بأن إعلانات الفيديو تتفوق في الأداء بشكل كبير على الإعلانات النصية أو البانرات ذات الصور فقط فيما يتعلق بالوصول الحقيقي للجمهور المستهدف والعائد على الاستثمار في الإعلانات.",
    Q_EN: "Can I run text and banner ads with Tadafuq? ",
    A_EN: "Yes you can, and you can additionally run video ads. Digital ad research shows that video ads out perform text or banners in terms of effective client outreach and return on investment.",
  },
  {
    Q: "كيف يمكنني أن أعلن مع شبكة تدفق؟",
    A: "بعد تزويدنا بالمعلومات المطلوبة في نموذج التسجيل للمعلنين، سنقوم بمراجعة معلومات حسابك. وحالما يتم اعتماده يمكنك الدخول للحساب وتجهيز المادة الأعلانية سواء فيديو أو بانرات الخاصة بحملتك، وبعدها يتم ربطها بالموقع الخاص بالإعلان. سوف تبدأ إعلاناتكم بالظهور مباشرة.",
    Q_EN: "How can I advertise with Tadafuq? ",
    A_EN: "Provide the required information in the Advertiser sign up form and we will review your account information. Once approved, you can log in and compose your video ad(s) and banners for your campaign and link these to your business/website. Your ads will start running instantaneously.",
  },
  {
    Q: "ماهي تكلفة الإعلان مع شبكة تدفق؟ ",
    A: "كمعلن، يمكنك تحديد الميزانية التي ترغب في إنفاقها على الإعلان وذلك عن طريق تحديد المبلغ الذي ترغب في دفعه مقابل مرات الظهور والنقرات وكذلك الحد الأعلى للنقرات ومرات الظهور لديك كمعلن تحكم كامل بمقدار الميزانية التي تود أن تنفقها على الإعلان وبشكل يومي.",
    Q_EN: "How much does it cost to advertise with Tadafuq? ",
    A_EN: "As an advertiser, you can specify how much you would like to spend per ad by entering how much you will pay for impressions and clicks and the place volumetric caps on impressions/clicks. You are in full control of how much you spend on daily.",
  },
  {
    Q: "هل هنالك حد أدنى من المدفوعات أو التزام زمني؟ ",
    A: "مع شبكة تدفق ليس هنالك أي مخاطرة في الالتزامات المالية أو حدود انتها مدة محددة. بعد أن تقوم بإنشاء حساب للمعلن، تحتاج تغذية الحساب بحد أدنى خمسين دولاراً وهو مبلغ بسيط وأيضاً قابل للاسترداد. ليس لدى شبكة تدفق حد أدنى للمدفوعات كما لا يوجد أي قيود زمنية أو أي رسوم",
    Q_EN: "Is there a minimum fee or time commitment?",
    A_EN: "After opening an advertiser account, you need to fund your account with a minimum refundable deposit of $50.00 USD. Tadafuq has no minimum spending limit, no time commitment or any extra fees.",
  },
  {
    Q: "كيف وأين ستظهر إعلاناتي؟",
    A: "ستظهر إعلاناتك في المواقع ذات العلاقة ضمن شبكة تدفق للإعلانات الرقمية. سيقوم النظام لدينا بوضع إعلاناتك في الصفحات ذات العلاقة بمحتوى الإعلان. كما يمكن أيضاً تحديد موقع معين وطريقة تصميم معينة لطريقة إدراج ",
    Q_EN: "Where and how will my ads appear?",
    A_EN: "Your ads will appear on the relevant websites within Tadafuq network. Our system will place your ads on pages that contain relevant content. And, you can specify specific publishers and design delivery specification for each insertion.",
  },
  {
    Q: "  هل من الممكن لقطاعات الأعمال أن تستهدف شريحة معينة من الجمهور المستهدفين في منطقة جغرافية محددة؟",
    A: "بكل تأكيد يمكن ذلك، لقد طورت شبكة تدفق تقنيات متطورة تمكن المعلن من أن يستهدف العملاء المتواجدين في منطقة جغرافية محددة بدقة متناهية. هذه الميزة التقنية تمكن المعلن من تقليل تكلفة الإنفاق على الإعلانات بحيث يتم استثمار كامل ميزاينة الإعلان على المنطقة الجغرافية المستهدفة فقط بأقل تكلفة. ",
    Q_EN: "Is it possible for small and mid-size business to target specific clients in a certain geographic area? ",
    A_EN: "Absolutely. Tadafuq has developed Micro geo-targeting technology that allows advertisers to target clients within very specific geographical area. This capability allows that advertisers to achieve great results with minimum investment.",
  },
  {
    Q: "متى يبدأ إعلاني بالظهور؟",
    A: "بمجرد أن تقوم بفتح حساب كمعلن مع شبكة تدفق وبعد أن تودع المبلغ المالي المحدد لحملاتك الإعلانية، سيتم اعتماد حملتك الإعلانية مباشرة وستبدأ إعلاناتك بالظهور مباشرة في مختلف مواقع شبكتنا، أو خلال الإطار الزمني الذي قمت بتحديده أثناء اختيارك لتفاصيل ظهور الإعلانات.",
    Q_EN: "When will my ads start running?",
    A_EN: "As soon as you open an advertisers account with Tadafuq and fund it, your Ad campaigns are instantly approved and will start running across our network of publishers instantly or within the time frames you specify in your delivery specifications.",
  },
  {
    Q: "ماهي أسباب عدم رؤية أرقام ظهور للإعلانات؟ ",
    A: "تأكد بأنه تم تغذية حسابك برصيد كاف، وأن معدلات دفع قيمة الإعلانات عالية بالقدر الكافي لتفوز بأعلى التصنيفات والمواقع في شبكتنا للمواقع الإلكترونية. تأكد بأنك قد قمت بتحديد فترة زمنية لظهور حملتك الإعلانية. ربما يكون التاريخ في فترة زمنية ماضية وليس في الوقت الحاضر.تحصل بعض أنواع الإعلانات على معدلات ظهور أعلى من بعض الإعلانات الأخرى. فإعلانات الفيديو والملتيميديا على سبيل المثال تحقق معدلات ظهور أعلى من أنواع الإعلانات النصية وغيرها. قم بزيادة قيمة عرضك على عدد مرات الظهور/النقر. كلما زاد عرض السعر الذي حددته، كلما زادت الفرصة بأن يتم عرض إعلانك. في بعض الأحيان يكون أعلى عرض لك للـ CPM/CPC مساوياً أو أعلى من عروض المعلنين الآخرين في نفس التصنيف. ",
    Q_EN: "What is the reason I am not seeing any impressions?",
    A_EN: "Make sure your account is funded and that your payment rates are high enough to win the top spots within our network of publishers. Make sure you specify a date range for which your campaign to be displayed. The date could have been specified in the past and not the current date. Some Ad types have much higher impressions than others. Video and Rich Media Creative Ad Types, for instance have, higher number of impressions than other Ad Types.Increase your CPM or CPC bid amount. The higher the Maximum amount you indicate, the more chances you have to get your ads displayed. Sometimes your Maximum CPC or CPM Bid is equal to or higher than other advertisers bidding under the same category.",
  },
  {
    Q: "لماذا لا تحقق إعلاناتي نقرات بينما هنالك آلاف المرات من الظهور  ؟ ",
    A: "أحد أهم الأسباب هو العنصر الإبداعي في الإعلان. أعد عمل الإعلان بطريقة احترافية تجعله يلفت انتباه الشريحة المستهدفة من العملاءs",
    Q_EN: "Why are my ads not getting clicks when they still have thousands of impressions?",
    A_EN: "One of the main reasons is how creative your Ad is composed. Recompose your ads such that it makes them more attractive to the targeted audience.",
  },
  {
    Q: "كيف يمكن لي أن أتأكد من حصول إعلاناتي على معدل عالي من مرات الظهور؟ ",
    A: "عن طريق رفع عرضك في المزاد لأعلى قيمة ممكنة مقابل عدد مرات الظهور/النقرات. ",
    Q_EN: "How can I insure that my ads get a high number of impressions? ",
    A_EN: "By bidding with highest possible amount in the Maximum CPM or CPC Bid amount. ",
  },
  {
    Q: " هل يمكنني وضع إعلانات للروابط التابعة affiliate links ؟",
    A: "نعم يمكنك وضع أي عدد من الإعلانات ترغب به في الروابط التابعة affiliate links",
    Q_EN: "Can I put ads to my affiliate links?",
    A_EN: "Yes, and you can put any number of ads you want in affiliate links.",
  },
  {
    Q: "هل تملك شبكة تدفق أي إجراءات لمنع التحايل؟ ",
    A: "بكل تأكيد، تملك شبكة تدفق أحدث تقنيات وأنظمة منع التحايل. فبالإضافة إلى المراقبة البشرية، فإننا نقوم بتحليل كل نقرة ومرات ظهور لتحديد ما إذا كانت حركة حقيقية أم أنها مصنعة أو مبرمجة غير حقيقية. يبدأ النظام بعد التحليل باستبعاد الحركة غير الفعلية من مرات الظهور والنقرات بشكل مباشر وآلي. بالنسبة للمعلن فإن النقرات ومرات الظهور الاحتيالية لا يتم احتسابها إطلاقاً ضمن القيمة المدفوعة للحملة. ",
    Q_EN: "Does Tadafuq have any fraud prevention measures?",
    A_EN: "Yes, Tadafuq has its own cutting edge fraud prevention system, along with a human monitoring, analyzing every click and impression to determine whether it is quality traffic or artificially generated. The system will automatically filter all invalid traffic and clicks in real time. You do not pay for any clicks or impressions that are determined to be fraudulent.",
  },
]
const QA2 = [
  {
    Q: "كيف يعمل برنامج شبكة تدفق للإعلانات الرقمية بالنسبة للناشر؟",
    A: "تدفق هي شبكة إعلانات رقمية تستهدف الإعلانات المناسبة للمحتوى، بحيث يسمح برنامج الناشر لأصحاب المواقع الإلكترونية، ومديري المواقع، والمدونين بمختلف الأحجام من أن يحققوا عوائد مالية عن طريق عرض الإعلانات ذات العلاقة بالمحتوى في مواقعهم أو مدوناتهم.البرنامج مجاني وتقوم الشبكة بدفع أرباح مالية محددة للناشرين مقابل مرات الظهور أو النقرات للإعلانات في مواقعهم. ولا يتطلب الأمر سوى لصق كود اتش تي إم إل في الموقع، وبعدها تبدأ الإعلانات في الظهور في الموقع ويبدأ معها تحقيق أرباح مالية للناشر. سيقوم المعلونون عبر شبكة تدفق بالمزايدة على المساحة المخصصة للإعلان في الموقع وستقوم الشبكة باختيار أعلى قيمة مقابل عرض الإعلان في موقعكم، مما يحقق لكم أعلى أرباح مالية مقابل المساحة الإعلانية",
    Q_EN: "How does the publisher program at Tadafuq work?",
    A_EN: "Tadafuq is a digital advertising network that contextually targets ads. The Publisher Program allows website owners, webmasters and bloggers of all sizes to earn money by displaying relevant ads on their websites or blogs. The program is free and Tadafuq pays publishers for all valid clicks and impressions on the ads on their website. You simply paste a HTML/JavaScript code into your web pages and ads will instantly start appearing. Advertisers will bid against each other for your ad space and our ads serving system will always display the highest bidder ads, generating the maximum revenue for your advertising space.",
  },
  {
    Q: "	كيف يمكنني التسجيل كناشر مع شبكة تدفق؟ ",
    A: "للتسجيل يشترط أن تكون مالكاً لموقع أو مدونة، وبعدها يتم إكمال وإرسال نموذج التسجيل. بالإمكان الوصول إلى رابط التسجيل كناشر في أعلى الصفحة الرئيسية بعنوان (التسجيل) ",
    Q_EN: "How do I sign up as publisher?",
    A_EN: "To sign up you must own a website, then you complete and submit the Publishers Program sign-up form. You can find the Publisher sign up link at the top of the main page under the Get Started tab.",
  },
  {
    Q: " كيف يتم اعتماد حسابي بعد إكمال عملية التسجيل؟",
    A: "كل ماعليك فعله هو التالي: ساعة بعد التسجيل سيصلك نموذج الاعتماد من فريقنا إذا كان موقعك الإلكتروني أو المدونة تحقق الحد الأدنى من المعايير. بعد أن يتم اعتماد حسابك، تقوم بالدخول للنظام وتبدأ بعمل الإعدادات المناسبة للمساحات الإعلانية في موقعك الإلكتروني وبعدها سيتم إنشاء كود الإعلانات في موقعك ، ثم تقوم بوضع كود الإعلانات في المساحات التي ترغب في تخصيصها لظهور الإعلانات في موقعك",
    Q_EN: "How do I get my account approved after signing up? ",
    A_EN: "All you need to do is the following: Sign up and in less than 24 hours from the time you sign up you will receive approval from our team if your websites meet our minimum criteria.Once approved, you log into the system and begin setting up your website inventory and then produce your invocation codes.Then you place the invocation codes in your website where you want the ads to appear.",
  },
  {
    Q: "ماهي أكثر الأسباب الشائعة التي تؤدي إلى رفض أو إيقاف حسابي؟",
    A: "ربما يعود سبب رفض أو إيقاف حسابك لأي من الأسباب التالية:ظهور إعلانات شبكتنا في أحد المواقع الممنوعة، مثل المواقع الإباحية، مواقع القمار، وغيرها من المواقع الممنوعة في قوانين شبكة تدفق للإعلانات النقر على إعلانات أو الطلب من الآخرين زيارة روابط مضللة بأي شكل من الأشكال، أو المشاركة في مخططات احتيالية لزيادة عدد مرات الظهور غير الحقيقية. امتلاكك لأكثر من حساب لدينا لنفس الموقع يؤدي إلى إيقاف كلا الحسابين التسجيل في حساب وإظهار إعلانات شبكتنا في حساب آخر. يمكنك إظهار إعلانات شبكتنا في أي عدد من المواقع التي تملكها، بشرط أن يتم ظهور الإعلانات في ذات الموقع الذي يحمل ملف التسجيل الخاص بك. إيقاف سابق لحسابك معنا. علما بأنه، في حال تم رفض طلبك، يمكنك التقدم بتسجيل حساب جديد. إظهار إعلانات شبكتنا في صفحات ذات محتوى نصي قليل أو في صفحات ليست ذات علاقة بموقعك، أو صفحات الغرض منها فقط عرض الإعلانات- عمل إعادة توجيه أو تمكين الإعلانات المنبثقة البوب أب .-	إظهار أكثر من ثلاثة وحدات إعلانية في الصفحة الواحدة.-إزالة كود الإعلانات من جميع المواقع الخاصة بك.-منع أدوات تحليل مقاييس الظهور .",
    Q_EN: "What would cause my account to be declined or suspended?",
    A_EN: "Your account could get declined or suspended for any of the following: Displaying our Ads on prohibited websites, such as adult, gambling, lottery or any inappropriate or illegal websites, as determined by Tadafuq.Clicking on the Ads or asking others to click by seducing them in any way or by participating in any pay to click or fraudulent impression generation schemes.Having more than one account with us will cause both accounts to be suspended.Signing up with one website and displaying our ads on a different website. You can display our ads on any number of websites that you own, however, you also MUST display the Ads on the very same website that is in your profile with us. Previously having a suspended account with us. However, if you have a declined account, you still can apply for a new account.Displaying our Ads on pages with little text or pages not related to your website, or pages just meant for displaying Ads.Generating redirection or enabling popups.Displaying more than 3 Ad units on one same page.Removing invocation codes from all your websites.Preventing visibility metrics from being captured.",
  },
  {
    Q: "	كيف تحدد شبكة تدفق للإعلانات الرقمية الإعلانات التي يتم عرضها؟ ",
    A: "	ستقوم الشبكة دائما بعرض الإعلانات ذات القيمة الأعلى في المزاد على المساحة الإعلانية في موقعك، وذلك للحرص على تحقيق أكبر عائد مالي ممكن في المدة الزمنية المحددة. سوف تبدأ بملاحظة التحسن في الأرباح لديكم مع الوقت. ",
    Q_EN: "How does Tadafuq decide which ads get displayed?",
    A_EN: "Tadafuq will always display the highest bidders on your website, assuring the maximum revenue possible at any given time. You should experience improvement in your earnings over time. ",
  },
  {
    Q: "	ماهو أقرب وقت يمكن أن تظهر فيه الإعلانات في الموقع الخاص بي؟ ",
    A: "	ستبدأ الإعلانات بالظهور فور إنشائك للحساب الخاص بك. كل ماعليك فعله هو اتباع هذه الخطوات البسيطة:- سجل الدخول لحسابك ,- من القائمة على الجهة اليسار انقر على المخزون ->المواقع->إضافة موقع جديد، ثم ابدأ بإنشاء المساحات الإعلانية الخاصة بك. -	بعد إكمال هذه الخطوة، قم بعمل الكود الإعلاني لكل مساحة إعلانية قمت بإنشائها.-قم بنسخ كود الإعلان الذي تم توليده وقم بلصقه في أي مكان في موقعك الإلكتروني أو مدونتك حيث تريد ظهور الإعلانات.  ",
    Q_EN: "How soon will Tadafuq ads start appearing on my website?",
    A_EN: "The ads will start appearing instantly once you create an account. Just follow these easy steps:Login to your account.From left menu click on Inventory -> Websites -> Add new website and then begin creating your zones. Once complete, generate the invocation code for each IAB zone created.Copy the HTML/Javascript invocation code that was generated and paste it in any space on your website or blog where you want the ads to show.",
  },
  {
    Q: " هل يمكنني التسجيل باستخدام حساباتي في يوتيوب أو فيس بوك؟ ",
    A: "لا، لايمكن إظهار إعلاناتنا في يوتيوب أو فيس بوك.",
    Q_EN: "Can I sign up with a youtube account or with a facebook account?",
    A_EN: "NO, you cannot place our ads on Facebook or YouTube.",
  },
  {
    Q: "	هل يمكنني إظهار الإعلانات في أكثر من موقع؟ ",
    A: "نعم، يمكنك إظهار الإعلانات في أي عدد من المواقع أو المدونات. فقط تحتاج أن تقوم بعمل وحدات إعلانية مستقلة لكل موقع أو مدونة. كما أن لديك الحرية لإنشاء قنوات أو وحدات إعلانية مختلفة.",
    Q_EN: "Can I show ads on more than one site?",
    A_EN: "Yes, you can show ads on any number of websites or blogs. You will need to create a separate ad units for each website or blog. You are also free to create different ad units and different channels.",
  },
  {
    Q: "	 هل يمكنني أن أضع أكثر من إعلان في الصفحة الواحدة من الموقع؟ ",
    A: "نعم، الحد الأقصى من الإعلانات الذي نوصي به هو ثلاث إعلانات في الصفحة الواحدة. ",
    Q_EN: "Can I place more than one ad on a page?",
    A_EN: "Yes, we suggest a maximum of 3 ads per page.",
  },
  {
    Q: "	هل يمكنني التغيير في كود الإعلان (html). ؟",
    A: "لا. يجب أن تقوم بلصق الكود الإعلاني الخاص بشبكة تدفق للإعلانات الرقمية في صفحات موقعك بدون عمل أي تعديلات.",
    Q_EN: " Can I alter the Tadafuq html code?",
    A_EN: "No. You should paste the Tadafuq invocation code into your web pages without making any modifications.",
  },
  {
    Q: "هل يمكنني إلغاء حسابي كناشر في شبكة تدفق؟ ",
    A: " بإمكانك إلغاء حسابك في أي وقت. فبمجرد حذفك للكود الإعلاني الخاص بشبكة تدفق للإعلانات الرقمية من موقعك لن تظهر بعدها إعلاناتنا في موقعك. لن يتم صرف أي مقابل مالي بعد إزالة كود الإعلانات الخاص بنا. بإمكانك كذلك التواصل معنا عبر البريد الإلكتروني وسنقوم بإلغاء حسابك عند رغبتك بذلك.  ",
    Q_EN: "Can I cancel my Tadafuq publisher account?",
    A_EN: "You can cancel your account anytime simply by removing the Tadafuq HTML Code from your website. No payment will be issued once the invocation code has been removed. You can email us and we will terminate your account.",
  },
  {
    Q: "	كيف يتم حصولي على العوائد المالية؟ ",
    A: "يتم تحويل مبالغ العوائد المالية التي تم تحقيقها من الإعلانات في موقعك عن طريق الحساب البنكي الخاص بك بشكل شهري. وذلك بعد 30 يوما من كل شهر ميلادي. لا تحتاج إلى أن تطلب التحويل المالي في كل مرة، فبمجرد تحقيقك للعوائد المالية سنقوم بإرسال بريد الكتروني يشعركم بأنه تم تحويل أرباحكم المالية من الإعلانات. ",
    Q_EN: "How are payments made?",
    A_EN: "Payments are sent via bank transfer on a monthly basis, within 30 days after the end of each calendar month. You do not need to request the payment, once you are eligible for payment, we will send you an email when payments have been transmitted.",
  },
  {
    Q: "متى سأبدأ بتحقيق العوائد المالية؟ ",
    A: "ستقوم شبكة تدفق للإعلانات الرقمية بإصدار المدفوعات المالية للناشرين بعد 30 يوما من نهاية كل شهر ميلادي، وبعد تحقيقكم لرصيد أرباح 10$ أو أكثر. إذا كان رصيدكم أقل من 10$ في نهاية الشهر، فسيتم ترحيل الرصيد للشهر القادم، وسيتم تحويلها لكم بمجرد تحقق عوائد مالية بقيمة 10$ أو أكثر",
    Q_EN: "When do I get paid?",
    A_EN: "Tadafuq will issue payment 30 days after the end of each month that your earning balance is US $10 or more. If your account's balance is not $10 at the end of a month, the balance will be carried over into the next month and paid out once you have earned $10 or more.",
  },
]

export default { advantages1, questions, QA, QA2, services }
