import React from "react"
import "./advantages.css"
import { data } from "../../constants"
import { useTranslation } from "react-i18next"

function AdvantageCard({ advantage: { imgUrl, title, enTitle } }) {
  const [t, i18n] = useTranslation()
  return (
    <div className="row">
      <div className="tadafuq__advantages-card">
        <div className="tadafuq__advantage-img_containor">
          <img src={imgUrl} alt="advantage" />
        </div>
        <div className="tadafuq__advantages-card_title">
          <p>{i18n.language == "en" ? enTitle : title}</p>
        </div>
      </div>
    </div>
  )
}

const Advantages = () => {
  return (
    <div className="tadafuq__advantages">
      <div className="tadafuq__grid">
        {data.advantages1.map(advantage => (
          <AdvantageCard advantage={advantage} key={advantage.title} />
        ))}
      </div>
    </div>
  )
}

export default Advantages
