import React from "react"
import { Footer } from "../../containers"
import { Navbar } from "../../components"
import Prcing from "../../components/pricing/Pricing"
import { useTranslation } from "react-i18next"

const Pricing = () => {
  const [t] = useTranslation()
  return (
    <div>
      <div className="App">
        <div className="img__background ">
          <Navbar />
          <div className="tadafuq__faqPage-banner section__padding flex__center">
        <div className="tadafuq__faqpage-banner_content">
          <h2>{t('pricing.pricing')}</h2>
          <h4>{t("pricing.headers")}</h4>
        </div>
      </div>
      </div>
    </div>
      <Prcing />
      <Footer />
    </div>
  )
}

export default Pricing
