import React from "react"
import "./signup.css"
import Forminput from "../../components/form/Forminput"
import { useState } from "react"
import { Navbar } from "../../components"
import { useTranslation } from "react-i18next"
const Signup = () => {
  const [t, i18n] = useTranslation()
  const placeholder = {
    email: t("placeholder_e"),
    password: t("password"),
    username: t("username"),
    surname: t("surname"),
    confirmPassword: t("confirmpassword"),
  }
  const errorMessage = {
    email: t("errorMessage_email"),
    password: t("errorMessage_password"),
    confirmpassword: t("errorMessage_confirm_password"),
  }
  const [values, setValues] = useState({
    email: "",
    username: "",
    surname: "",
    Birthday: "",
    password: "",
    confirmPassword: "",
  })
  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: placeholder.email,
      errorMessage: errorMessage.email,
      label: placeholder.email + ":",
      required: true,
    },

    {
      id: 2,
      name: "username",
      type: "text",
      placeholder: placeholder.username,
      errorMessage: "",
      label: placeholder.username + ":",
      required: true,
    },
    {
      id: 3,
      name: "surname",
      type: "text",
      placeholder: placeholder.surname,
      errorMessage: "",
      label: placeholder.surname + ":",
      required: true,
    },
    // {
    //   id: 4,
    //   name: "Birthday",
    //   type: "date",
    //   placeholder: "تاريخ الميلاد",
    //   label: " تاريخ الميلاد :",
    // },
    {
      id: 5,
      name: "password",
      type: "password",
      placeholder: placeholder.password,
      errorMessage: errorMessage.password,
      label: placeholder.password + ":",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 6,
      name: "confirmPassword",
      type: "password",
      placeholder: placeholder.confirmPassword,
      errorMessage: errorMessage.confirmpassword,
      label: placeholder.confirmPassword + ":",
      pattern: values.password,
      required: true,
    },
  ]
  const handelSubmit = e => {
    e.preventDefault()
  }

  const onChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  console.log(values)
  return (
    <div className="tadafuq__signup">
      <Navbar />
      <div className="tadafuq__signup-form flex__center">
        <form onSubmit={handelSubmit}>
          <h1>{t("signUp_title")}</h1>
          {inputs.map(input => (
            <Forminput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
          ))}
          <div className="labels">
            <div className="checkbox-form">
              <input type="checkbox" />
              <label htmlFor="#"> {t("privacy policy")}</label>
              <br />
              <input type="checkbox" />
              <label htmlFor="#"> {t("newsletter")}</label>
            </div>
          </div>
          <button>{t("signUp_title")}</button>
        </form>
      </div>
      <div className="footer__copyright flex__center">
        <p>{t("copyRight")}</p>
      </div>
    </div>
  )
}

export default Signup
