import React from "react"
import "./footer.css"
import { images } from "../../constants"
import { Menu } from "../../components/navbar/menu"
import { useTranslation } from "react-i18next"

const Footer = () => {
  const [t, i18n] = useTranslation()
  return (
    <div className="tadafuq__footer section__padding  flex__center">
      <div className="tadafuq__footer-img flex__center">
        {i18n.language == "en" ? <img src={images.eflogo} alt="logo" /> : <img src={images.flogo} alt="logo" />}
      </div>

      <div className="tadafuq__footer-links">
        <Menu />
      </div>
      <hr />
      <div className="tadafuq__footer-contact flex__center">
        <p className="contact-title">{t("contact_us")}</p>
        <div className="tadafuq__footer-contact_info">
          <div className=" tadafuq__footer-contact_content">
            <p> {t("phone")}</p>
            <img src={images.whatsup} alt="whatsupicon" />
          </div>
          <div className=" tadafuq__footer-contact_content">
            <p> {t("phone")}</p>

            <img src={images.phone} alt="phoneicon" />
          </div>
          <div className=" tadafuq__footer-contact_content">
            <a>aal@tfarraj.tv</a>
            <img src={images.mail} alt="mailicon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
