import React from "react"
import { Navbar } from "../../components"
import { Footer } from "../../containers"
import Forminput from "../../components/form/Forminput"
import { useState } from "react"
import "./contactus.css"
import { useTranslation } from "react-i18next"
const ContactUs = () => {
  const [t, i18n] = useTranslation()
  const placeholder = {
    email: t("placeholder_e"),
    fullname: t("full_name"),
    subject: t("subject"),
  }
  const errorMessage = {
    email: t("errorMessage_email"),
  }
  const [values, setValues] = useState({
    email: "",
    fullname: "",
    subject: "",
  })
  const inputs = [
    {
      id: 1,
      name: "fullname",
      type: "text",
      placeholder: placeholder.fullname,
      errorMessage: "",
      label: placeholder.fullname + ":",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: placeholder.email,
      errorMessage: errorMessage.email,
      label: placeholder.email + ":",
      required: true,
    },

    {
      id: 3,
      name: "subject",
      type: "text",
      placeholder: placeholder.subject,
      errorMessage: "",
      label: placeholder.subject + ":",
      required: true,
    },
  ]
  const handelSubmit = e => {
    e.preventDefault()
  }

  const onChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  return (
    <div className="tadafuq__contactus ">
      <Navbar />
      <div className="tadafuq__contactus-content section__padding ">
        <div className="tadafuq__contactus-content_section1">
          <h2>{t("contactUs")}</h2>
          <p>
            {t("thanks_message")} <a href="#">aal@tfarraj.tv</a>
          </p>
        </div>
        <div className="tadafuq__contactus-content_section2 ">
          <form onSubmit={handelSubmit}>
            <h1>{t("sendMessage")}</h1>
            {inputs.map(input => (
              <Forminput key={input.id} {...input} value={values[input.name]} onChange={onChange} />
            ))}
            <div className="textarea-form">
              <label htmlFor="message"> {t("message")}</label>
              <textarea></textarea>
            </div>

            <button>{t("send")}</button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
