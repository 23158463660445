import React from "react"
import { Header, Footer, About, Services, Questions } from "../../containers"
import { Navbar } from "../../components"

const Home = () => {
  return (
    <div>
      <div className="App">
        <div className="img__background ">
          <Navbar />
          <Header />
        </div>
        <About />
        <Services />
        <Questions />
      </div>

      <Footer />
    </div>
  )
}

export default Home
