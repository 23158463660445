import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import React from "react"
export function Menu() {
  const [t, i18n] = useTranslation()

  return (
    <>
      <ul>
        <li>
          <Link to="/">{t("navmain")}</Link>
        </li>
        <li>
          <Link to="/FQAdvertiser">{t("navAdvertiser")}</Link>
        </li>
        <li>
          <Link to="/FAQPublisher">{t("navPublisher")}</Link>
        </li>
        <li>
          <Link to="/Pricing">{t("pricing.pricing")}</Link>
        </li>
        <li>
          <Link to="/Login">{t("navLogin")}</Link>
        </li>
        <li>
          {i18n.language == "ar" && (
            <li
              className="langButton"
              onClick={() => {
                i18n.changeLanguage("en")
              }}
            >
              English
            </li>
          )}

          {i18n.language == "en" && (
            <li
              className="langButton"
              onClick={() => {
                i18n.changeLanguage("ar")
              }}
            >
              العربية
            </li>
          )}
        </li>
      </ul>
    </>
  )
}
