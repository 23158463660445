import React from "react"
import "./header.css"
import headerImage from "../../assets/images/headerimg.png"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
const Header = () => {
  const [t, i18n] = useTranslation()
  return (
    <div className="
      tadafuq__header section__padding   
    " id="home">
      <div className="
        tadafuq__header-content
      ">
        <div className="tadafuq__header-content_text ">
          <p>{t("title")}</p>
        </div>
        <Link to="ContactUs">
          <button type="button">{t("contactbutton")}</button>
        </Link>
        <div className="tadafuq__header-content_img">
          {" "}
          <img src={headerImage} alt="advertiser" />
        </div>
      </div>
    </div>
  )
}

export default Header
