import React from "react"
import "./faq.css"
import { data } from "../../constants"
import arrow from "../../assets/images/arrow.svg"
import { useState } from "react"
import { useTranslation } from "react-i18next"
const FAQ2 = () => {
  const [t, i18n] = useTranslation()
  const [selected, setSelected] = useState(null)
  const toggle = i => {
    if (selected == i) {
      return setSelected(null)
    }

    setSelected(i)
  }

  return (
    <div className="tadafuq__FAQ  section__padding  ">
      {data.QA2.map((item, i) => (
        <div className="item">
          <div className="thequestion " onClick={() => toggle(i)}>
            <h2>{i18n.language == "en" ? item.Q_EN : item.Q}</h2>
            <div className="arrow">{selected == i ? "- " : <img src={arrow} alt="arrow" />}</div>
          </div>
          <div className={selected == i ? "theanswer show" : "theanswer"}>
            {i18n.language == "en" ? item.A_EN : item.A}
          </div>
        </div>
      ))}
      <div className="tadafuq__faqPage-more section__padding">
        <p>{t("FAQ_help")}</p>
      </div>
    </div>
  )
}

export default FAQ2
