import React from "react"
import { Link } from "react-router-dom"
import "./cards.css"
import { data } from "../../constants"
import { useTranslation } from "react-i18next"
function QuestionCard({
  question: { imgUrl, question, answer, category, QALink, category_EN, question_EN, answer_EN },
}) {
  const [t, i18n] = useTranslation()
  return (
    <div className="card__content">
      <img src={imgUrl} alt="cardimg" />
      <div className="category">
        <h6>{i18n.language == "en" ? category_EN : category}</h6>
      </div>
      <h2>{i18n.language == "en" ? question_EN : question}</h2>
      <div className="card__answer">
        <p>
          {i18n.language == "en" ? answer_EN : answer} <Link to={QALink}> {t("more")}</Link>
        </p>
      </div>
    </div>
  )
}
const Cards = () => {
  return (
    <div className="tadafuq__question-cards">
      {data.questions.map(question => (
        <QuestionCard question={question} key={question.question} />
      ))}
    </div>
  )
}

export default Cards
