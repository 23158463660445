import React from "react"
import "./about.css"
import { Advantages } from "../../components"
import { useTranslation } from "react-i18next"

const About = () => {
  const [t, i18n] = useTranslation()
  return (
    <div 
    style={{paddingTop: '30%'}}
    className="tadafuq__about section__padding" id="about">
      <div className="sized_box"></div>
      <div className="tadafuq__about-text_title ">
        <div className="tadafuq_about-title">
          <div>
            <h2>{t("aboutitle")}</h2>
          </div>
        </div>
        <div className="tadafuq__about-subtitle">
          {" "}
          <p>{t("aboutsubtitle")} </p>
        </div>

        <div className="tadafuq__about-content">
          <Advantages />
        </div>
      </div>
    </div>
  )
}

export default About
