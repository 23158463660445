import React from "react"
import "./services.css"
import { data } from "../../constants"
import servicesimg from "../../assets/images/servicesimg.png"
import tvector from "../../assets/images/textvector.png"
import { useTranslation } from "react-i18next"
function ServicesList({ service: { title, enTitle } }) {
  const [t, i18n] = useTranslation()
  return (
    <div className="services__list">
      <ul>
        <li>{i18n.language == "en" ? enTitle : title}</li>
      </ul>
    </div>
  )
}
const Services = () => {
  const [t] = useTranslation()
  return (
    <div className="Services  flex__center ">
      <div className="tadafuq__services-bg  ">
        <div className="tadafuq__services-text">
          <div className="tadafuq__services-text_title">
            <div>
              <p>{t("services_title")}</p>
            </div>
          </div>
          <div>
            {data.services.map(service => (
              <ServicesList service={service} key={service.title} />
            ))}
          </div>
        </div>

        <div className="tadafuq__services-img">
          <img src={servicesimg} alt="tadafuq-img" />
        </div>
      </div>
    </div>
  )
}

export default Services
